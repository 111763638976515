import { importProvidersFrom, NgModule } from "@angular/core";
import { NoPreloading, RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard";
import { NoneRoleComponent } from "@app/components/none-role/none-role.component";
import {
  ReconciliationStatementsProtocolComponent
} from "./modules/admin/components/uploads/reconciliation-statements-protocol/reconciliation-statements-protocol.component";
import { AuthComponent } from "@app/eager-modules/auth/components/auth/auth.component";
import {
  MassCreateAccountsProtocolComponent
} from "@app/dictionaries/address/house/components/mass-create-accounts/mass-create-account-protocol.component";
import { StoreModule } from "@ngrx/store";
import { authNode } from "@app/eager-modules/auth/store/state/auth.state";
import { AuthReducer } from "@app/eager-modules/auth/store/reducer/auth.reducer";
import { EffectsModule } from "@ngrx/effects";
import { AuthEffects } from "@app/eager-modules/auth/store/effects/auth.effects";

export const routes: Routes = [
  {
    path: "mass-create-accounts-protocol",
    component: MassCreateAccountsProtocolComponent,
    data: {
      title: "Протокол по массовому созданию лс",
      componentName: "MassCreateAccountsProtocolComponent"
    }
  },
  {
    path: `reconciliation-statements-protocol`,
    component: ReconciliationStatementsProtocolComponent
  },
  { path: "none-role", component: NoneRoleComponent },
  {
    path: "print",
    loadChildren: () => import("./modules/print/print.routes").then(m => m.PRINT_ROUTES)
  },
  {
    path: "auth",
    component: AuthComponent,
    title: "Авторизация",
    providers: [
      importProvidersFrom(
        StoreModule.forFeature(authNode, AuthReducer.reducer),
        EffectsModule.forFeature([AuthEffects]),
      )
    ]
  },
  {
    path: "system",
    canActivate: [AuthGuard],
    loadChildren: () => import("./modules/system/system.module").then((m) => m.SystemModule),
    pathMatch: "prefix"
  },
  { path: "**", redirectTo: "system" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
