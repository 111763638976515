export const alphaProfiles = {
  "interfaceProfileLinksStorage": {
    "interfaceProfileLinks": [
      {
        "positionName": null,
        "departmentName": null,
        "organizationId": 6056,
        "branchId": null,
        "interfaceProfiles": [{ "default": true, "id": "ea60ef56-bae3-4c60-b867-a36728c62b81" }],
        "positionId": null,
        "organizationName": null,
        "departmentId": null,
        "branchName": null,
        "id": "6056",
        "userName": null,
        "userId": null
      },
      {
        "positionName": null,
        "departmentName": null,
        "organizationId": 7,
        "branchId": null,
        "interfaceProfiles": [{ "default": true, "id": "705d410d-e637-45e1-985b-df9035489faf" }],
        "positionId": null,
        "organizationName": null,
        "departmentId": null,
        "branchName": null,
        "id": "7",
        "userName": null,
        "userId": null
      }
    ]
  }, "interfaceProfilesStorage": {
    "interfaceProfiles": [
      {
        "settings": {
          "header": {
            "trashCheckHousePolicyReport": false,
            "houseGroupLink": true,
            "company_manage_item": true,
            "notice-register": false,
            "invoice-pattern-detail-manage": false,
            "paymentDebtorsPeriodReport": false,
            "utilities": true,
            "branch": true,
            "deleteAdvance": false,
            "trashTurnoverReport": false,
            "bank": false,
            "turnoverReportByTown": false,
            "balance": false,
            "ownership-type": true,
            "restructuringsListReport": false,
            "turnoverByAccountRegisterUpload": false,
            "invoice-delivery-route-manage": false,
            "invoiceCountReport": false,
            "recalculation-basis": true,
            "utility-group": true,
            "calculations_paragraph": false,
            "inspection": false,
            "paymentReport": false,
            "invoiceMake": false,
            "bailiff-departments": false,
            "service-organizations": true,
            "massPoliciesEnable": false,
            "payee-account-manage": false,
            "balanceMonth": false,
            "notice-delivery-type": false,
            "utility_header_item": true,
            "accompanyingListReport": false,
            "accountListsReport": false,
            "actCauseManage": true,
            "account_header_item": true,
            "registerCanceledOrderReport": false,
            "policy-subject-type-manage": false,
            "meterValueLoad": false,
            "unloadForCurog": false,
            "house-meter-value-type-manage": true,
            "meter-value-sources": true,
            "odpu_header_item": true,
            "fiscalization-policy-manage": false,
            "unloads": false,
            "mun-area": true,
            "balanceDelete": false,
            "compensationUpload": false,
            "service_paragraph_dictionaries_label": false,
            "company-manage": true,
            "mun-town": true,
            "payments_paragraph_payment_distribution_header_item": false,
            "invoices_paragraph": false,
            "meter-device-models": true,
            "recalc": false,
            "gas-station-house-manage": true,
            "paymentRegisterReport": false,
            "accountHistoryReport": false,
            "lawsuitCountReport": false,
            "account-note-open": true,
            "gas-station": true,
            "turnoverReport": false,
            "reconciliationActs": false,
            "courtOrderPaymentsReport": false,
            "season-type-manage": true,
            "balance_paragraph": false,
            "invoiceFix": false,
            "fineActPolicies": false,
            "deleteRecalculation": false,
            "analytic_paragraph": false,
            "decision": false,
            "balanceDeleteMonth": false,
            "turnoverByAreaReport": false,
            "routesListReport": false,
            "summaryAccountReceivableReport": false,
            "payee-organization-manage": false,
            "paymentDistributionCheckReport": false,
            "fineRates": false,
            "meter_header_item": true,
            "invoiceDebtorCountReport": false,
            "dictionaries_paragraph": true,
            "gaspromTurnoverReport": false,
            "calc": false,
            "representatives": false,
            "checking-account": false,
            "season_header_item": true,
            "communal-resource-type": true,
            "reports": false,
            "notice-type": false,
            "invoicePrint": false,
            "service_paragraph": false,
            "inspection-results": true,
            "migrate-apartment-manage": false,
            "fact-accrued-houses": false,
            "operator": false,
            "absenceReport": false,
            "meter-doc-types": true,
            "tariff-category": true,
            "tariff_header_item": true,
            "balance_paragraph_dictionaries_label": false,
            "createAdvance": false,
            "account-note-close": true,
            "trashSummaryReport": false,
            "debtors": false,
            "registers": false,
            "unloadAutoRecalc": false,
            "act_header_item": true,
            "service_paragraph_report_header_item": false,
            "tariffs": true,
            "area": true,
            "resource-supply-type-manage": true,
            "invoicePrintFile": false,
            "invoicePrintFileForGes": false,
            "town": true,
            "law-district": false,
            "organization_header_item": true,
            "absence-document-type": true,
            "management-manage": true,
            "basis-document-type": true,
            "alias-type": true,
            "contractor-manage": true,
            "payee-bank-manage": false,
            "reports_paragraph": false,
            "unit": true,
            "term-basis-prod-activity": false,
            "gas_header_item": true,
            "accountInfoReport": false,
            "turnoverByAccountDBFReport": false,
            "tenant-document-type": true,
            "calculation-average-policy-manage": false,
            "position": true,
            "taxStatementReport": false,
            "house-meter-type-manage": true,
            "region": true,
            "finePolicies": false,
            "wall-material-manage": true,
            "act-initiator-manage": true,
            "meter-value-restriction": true,
            "accountReceivableReport": false,
            "routesListInvoiceCountReport": false,
            "countAccountByGroupReport": false,
            "payment-channel-manage": false,
            "house": true,
            "payeeConsolidatedDischargeReport": false,
            "law-register": false,
            "trashDebtorsReport": false,
            "recalculationRegisterReport": false,
            "percentOfPaymentReport": false,
            "actManage": false,
            "street": true,
            "gas-stove-document-type": true,
            "checkHousesInGroupReport": false,
            "policy-method-type-manage": false,
            "migrate-apartment-account": false,
            "law-house": false,
            "payments_paragraph": false,
            "fast_input_paragraph": true,
            "department": true,
            "plugs-causes-manage": true,
            "lawsuitWorkDoneReport": false,
            "address_header_item": true,
            "lawRegisterReprint": false,
            "notice-delivery-note": false,
            "payee-order-note-manage": false,
            "service_paragraph_balance_label": false,
            "deliveryRejection": false,
            "invoiceUnfix": false,
            "tariff-basis": true,
            "apartment_header_item": true,
            "unloadRecalc": false,
            "lawRegisterPrint": false,
            "tax-register": false,
            "execution-rejection": false,
            "maintenance": true,
            "bank-manage": false,
            "invoiceMakeFile": false,
            "installmentChallengersReport": false,
            "installmentCancelChallengersReport": false,
            "paymentsStructureReport": false,
            "createdRestructuringReport": false,
            "adjustmentDayReport": false,
            "recalculationPaymentByOperationalDayReport": false
          },
          "account": {
            "6056": {
              "blocks": {
                "ACCOUNT_UTILITIES": true,
                "ACCOUNT_METER_IMPOSSIBLE_INSTALL_DOCUMENTS": true,
                "ACCOUNT_OUTBUILDING_AREA": true,
                "ACCOUNT_METERS": true,
                "ACCOUNT_RESOURCE_SUPPLY": true,
                "ACCOUNT_TOTAL_AREA": true,
                "ACCOUNT_OWNERS": true,
                "ACCOUNT_MULTIPLICITY_TYPE": true,
                "ACCOUNT_DISABLE_AVERAGE": true,
                "ACCOUNT_USE_TOTAL_AREA": true,
                "ACCOUNT_TENANTS": true,
                "ACCOUNT_GAS_MAINTENANCE_WORK": true,
                "ACCOUNT_DISABLE_NORM": true,
                "ACCOUNT_RESIDENTIAL_AREA": true,
                "ACCOUNT_PLUGS": true,
                "ACCOUNT_WATER_HEATER": true,
                "ACCOUNT_RESIDENTS": true,
                "ACCOUNT_GAS_MAINTENANCE_CONTRACT": true,
                "ACCOUNT_GAS_STOVE": true
              }, "menu": {
                "recalculationAutomatic": true,
                "accountCalculation": true,
                "notice\/notice-manage": true,
                "invoice-manage": true,
                "recalculation-manage": true,
                "recalculation-sum-manage": true,
                "reconciliationWithPaymentsReport": false,
                "act-sum-manage": true,
                "lawsuit-file-manage": true,
                "recalculationManual": true,
                "accountAbsenceInstantReport": false,
                "calculation-fine-manage": true,
                "turnover-manage-byMonth": true,
                "account_balance_paragraph": true,
                "act-manage": true,
                "accountTurnoverReport": false,
                "account_calculations_paragraph_label": true,
                "account_recalculations_paragraph": true,
                "fineReconciliationReport": false,
                "taxReconciliationReport": false,
                "form36InstantReport": false,
                "delivery-rejection-manage": false,
                "account_calculations_act_paragraph_label": true,
                "all-payments": true,
                "turnover-manage": true,
                "calculation-manage": true,
                "restructuring": true,
                "calcDebtsReport": true,
                "rosreestrStatement": false,
                "account_calculations_paragraph": true,
                "reconciliationMeterReport": false,
                "accountBalanceReconciliationReport": false,
                "tenantCardReport": false,
                "lawsuits": true,
                "reconciliationReport": true,
                "accountTariffs": true,
                "account_documents_paragraph": true,
                "apartmentOrderInstantReport": false,
                "payment-manage": true,
                "execution-documents": true,
                "account_payments_paragraph": true,
                "navigateToAccountScansForAlpha": true,
                "accountDebtManagement": false,
                "createAccountDebt": false,
                "deleteAccountDebt": false
              }
            }
          },
          "apartment": {
            "absences": true,
            "meterDocuments": true,
            "resourceSupplies": true,
            "plugs": true,
            "ownerships": true,
            "residents": true,
            "gasStoveDocuments": true,
            "meterImpossibleDocuments": true,
            "properties": true,
            "gasContractsWorks": true
          }
        }, "name": "хэс", "id": "ea60ef56-bae3-4c60-b867-a36728c62b81"
      }, {
        "settings": {
          "header": {
            "trashCheckHousePolicyReport": false,
            "houseGroupLink": true,
            "company_manage_item": true,
            "notice-register": false,
            "invoice-pattern-detail-manage": false,
            "paymentDebtorsPeriodReport": false,
            "utilities": true,
            "branch": true,
            "deleteAdvance": false,
            "trashTurnoverReport": false,
            "bank": false,
            "turnoverReportByTown": false,
            "balance": false,
            "ownership-type": true,
            "restructuringsListReport": false,
            "turnoverByAccountRegisterUpload": false,
            "invoice-delivery-route-manage": false,
            "invoiceCountReport": false,
            "recalculation-basis": true,
            "utility-group": true,
            "calculations_paragraph": false,
            "inspection": false,
            "paymentReport": false,
            "invoiceMake": false,
            "bailiff-departments": false,
            "service-organizations": true,
            "massPoliciesEnable": false,
            "payee-account-manage": false,
            "balanceMonth": false,
            "notice-delivery-type": false,
            "utility_header_item": true,
            "accompanyingListReport": false,
            "accountListsReport": false,
            "actCauseManage": true,
            "account_header_item": true,
            "registerCanceledOrderReport": false,
            "policy-subject-type-manage": false,
            "meterValueLoad": false,
            "unloadForCurog": false,
            "house-meter-value-type-manage": true,
            "meter-value-sources": true,
            "odpu_header_item": true,
            "fiscalization-policy-manage": false,
            "unloads": false,
            "mun-area": true,
            "balanceDelete": false,
            "compensationUpload": false,
            "service_paragraph_dictionaries_label": false,
            "company-manage": true,
            "mun-town": true,
            "payments_paragraph_payment_distribution_header_item": false,
            "invoices_paragraph": true,
            "meter-device-models": true,
            "recalc": false,
            "gas-station-house-manage": true,
            "paymentRegisterReport": false,
            "accountHistoryReport": false,
            "lawsuitCountReport": false,
            "account-note-open": true,
            "gas-station": true,
            "turnoverReport": false,
            "reconciliationActs": false,
            "courtOrderPaymentsReport": false,
            "season-type-manage": true,
            "balance_paragraph": false,
            "invoiceFix": false,
            "fineActPolicies": false,
            "deleteRecalculation": false,
            "analytic_paragraph": false,
            "decision": false,
            "balanceDeleteMonth": false,
            "turnoverByAreaReport": false,
            "routesListReport": false,
            "summaryAccountReceivableReport": false,
            "payee-organization-manage": false,
            "paymentDistributionCheckReport": false,
            "fineRates": false,
            "meter_header_item": true,
            "invoiceDebtorCountReport": false,
            "dictionaries_paragraph": true,
            "gaspromTurnoverReport": false,
            "calc": false,
            "representatives": false,
            "checking-account": false,
            "season_header_item": true,
            "communal-resource-type": true,
            "reports": false,
            "notice-type": false,
            "invoicePrint": false,
            "service_paragraph": false,
            "inspection-results": true,
            "migrate-apartment-manage": false,
            "fact-accrued-houses": false,
            "operator": false,
            "absenceReport": false,
            "meter-doc-types": true,
            "tariff-category": true,
            "tariff_header_item": true,
            "balance_paragraph_dictionaries_label": false,
            "createAdvance": false,
            "account-note-close": true,
            "trashSummaryReport": false,
            "debtors": false,
            "registers": false,
            "unloadAutoRecalc": false,
            "act_header_item": true,
            "service_paragraph_report_header_item": false,
            "tariffs": true,
            "area": true,
            "resource-supply-type-manage": true,
            "invoicePrintFile": false,
            "invoicePrintFileForGes": true,
            "town": true,
            "law-district": false,
            "organization_header_item": true,
            "absence-document-type": true,
            "management-manage": true,
            "basis-document-type": true,
            "alias-type": true,
            "contractor-manage": true,
            "payee-bank-manage": false,
            "reports_paragraph": false,
            "unit": true,
            "term-basis-prod-activity": false,
            "gas_header_item": true,
            "accountInfoReport": false,
            "turnoverByAccountDBFReport": false,
            "tenant-document-type": true,
            "calculation-average-policy-manage": false,
            "position": true,
            "taxStatementReport": false,
            "house-meter-type-manage": true,
            "region": true,
            "finePolicies": false,
            "wall-material-manage": true,
            "act-initiator-manage": true,
            "meter-value-restriction": true,
            "accountReceivableReport": false,
            "routesListInvoiceCountReport": false,
            "countAccountByGroupReport": false,
            "payment-channel-manage": false,
            "house": true,
            "payeeConsolidatedDischargeReport": false,
            "law-register": false,
            "trashDebtorsReport": false,
            "recalculationRegisterReport": false,
            "percentOfPaymentReport": false,
            "actManage": false,
            "street": true,
            "gas-stove-document-type": true,
            "checkHousesInGroupReport": false,
            "policy-method-type-manage": false,
            "migrate-apartment-account": false,
            "law-house": false,
            "payments_paragraph": false,
            "fast_input_paragraph": true,
            "department": true,
            "plugs-causes-manage": true,
            "lawsuitWorkDoneReport": false,
            "address_header_item": true,
            "lawRegisterReprint": false,
            "notice-delivery-note": false,
            "payee-order-note-manage": false,
            "service_paragraph_balance_label": false,
            "deliveryRejection": false,
            "invoiceUnfix": false,
            "tariff-basis": true,
            "apartment_header_item": true,
            "unloadRecalc": false,
            "lawRegisterPrint": false,
            "tax-register": false,
            "execution-rejection": false,
            "maintenance": true,
            "bank-manage": false,
            "invoiceMakeFile": false,
            "installmentChallengersReport": false,
            "installmentCancelChallengersReport": false,
            "paymentsStructureReport": false,
            "createdRestructuringReport": false,
            "adjustmentDayReport": false,
            "recalculationPaymentByOperationalDayReport": false
          },
          "account": {
            "7": {
              "blocks": {
                "ACCOUNT_UTILITIES": true,
                "ACCOUNT_METER_IMPOSSIBLE_INSTALL_DOCUMENTS": true,
                "ACCOUNT_OUTBUILDING_AREA": true,
                "ACCOUNT_METERS": true,
                "ACCOUNT_RESOURCE_SUPPLY": true,
                "ACCOUNT_TOTAL_AREA": true,
                "ACCOUNT_OWNERS": true,
                "ACCOUNT_MULTIPLICITY_TYPE": true,
                "ACCOUNT_DISABLE_AVERAGE": true,
                "ACCOUNT_USE_TOTAL_AREA": true,
                "ACCOUNT_TENANTS": true,
                "ACCOUNT_GAS_MAINTENANCE_WORK": true,
                "ACCOUNT_DISABLE_NORM": true,
                "ACCOUNT_RESIDENTIAL_AREA": true,
                "ACCOUNT_PLUGS": true,
                "ACCOUNT_WATER_HEATER": true,
                "ACCOUNT_RESIDENTS": true,
                "ACCOUNT_GAS_MAINTENANCE_CONTRACT": true,
                "ACCOUNT_GAS_STOVE": true
              }, "menu": {
                "recalculationAutomatic": true,
                "accountCalculation": true,
                "notice\/notice-manage": true,
                "invoice-manage": true,
                "recalculation-manage": true,
                "recalculation-sum-manage": true,
                "reconciliationWithPaymentsReport": false,
                "act-sum-manage": true,
                "lawsuit-file-manage": true,
                "recalculationManual": true,
                "accountAbsenceInstantReport": false,
                "calculation-fine-manage": true,
                "turnover-manage-byMonth": true,
                "account_balance_paragraph": true,
                "act-manage": true,
                "accountTurnoverReport": false,
                "account_calculations_paragraph_label": true,
                "account_recalculations_paragraph": true,
                "fineReconciliationReport": true,
                "taxReconciliationReport": false,
                "form36InstantReport": true,
                "delivery-rejection-manage": false,
                "account_calculations_act_paragraph_label": true,
                "all-payments": true,
                "turnover-manage": true,
                "calculation-manage": true,
                "restructuring": true,
                "calcDebtsReport": true,
                "rosreestrStatement": false,
                "account_calculations_paragraph": true,
                "reconciliationMeterReport": true,
                "accountBalanceReconciliationReport": false,
                "tenantCardReport": false,
                "lawsuits": true,
                "reconciliationReport": true,
                "accountTariffs": true,
                "account_documents_paragraph": true,
                "apartmentOrderInstantReport": false,
                "payment-manage": true,
                "execution-documents": true,
                "account_payments_paragraph": true,
                "navigateToAccountScansForAlpha": true,
                "accountDebtManagement": false,
                "createAccountDebt": false,
                "deleteAccountDebt": false
              }
            }
          },
          "apartment": {
            "absences": true,
            "meterDocuments": true,
            "resourceSupplies": true,
            "plugs": true,
            "ownerships": true,
            "residents": true,
            "gasStoveDocuments": true,
            "meterImpossibleDocuments": true,
            "properties": true,
            "gasContractsWorks": true
          }
        }, "name": "гэс", "id": "705d410d-e637-45e1-985b-df9035489faf"
      }
    ]
  }
};
