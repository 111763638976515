@if (authState$ | async; as state) {
  @if (authForm) {
    <section class = "login-wrapper">
      <section class = "login">
        <header class = "auth-header">
          <span>Авторизация</span>
          <cds-icon
            [shape] = "'cog'"
            [solid] = "state.isServerAndPortVisible"
            [size] = "'20'"
            (click) = "changeServerAndPortVisibility()"
          ></cds-icon>
        </header>
        <form [formGroup] = "authForm">
          @if (state.isServerAndPortVisible) {
            <section class = "clr-control-container">
              <section class = "clr-select-wrapper">
                <select [formControl] = "selectedServer" [compareWith] = "compareSelectedServer" class = "clr-select">
                  @for (server of servers; track server) {
                    <option [ngValue] = "server">{{ server.name }}</option>
                  }
                </select>
              </section>
            </section>
          }
          @if (selectedServer.value.name === 'Ввести ip-адрес сервера') {
            <section class = "server-and-port-container">
              <section [ngClass] = "{ 'clr-error': isControlHasErrors(serverIp) }" class = "clr-control-container">
                <section class = "clr-input-wrapper">
                  <input
                    [formControl] = "serverIp"
                    [placeholder] = "'ip-адрес сервера'"
                    [type] = "'text'"
                    required
                    class = "clr-input"
                  >
                  <cds-icon class = "clr-validate-icon" shape = "exclamation-circle"></cds-icon>
                </section>
                @if (isControlHasErrors(serverIp)) {
                  <span class = "clr-subtext">{{ getControlErrorMessage(serverIp) }}</span>
                }
              </section>
              <section [ngClass] = "{'clr-error': isControlHasErrors(port) }" class = "clr-control-container">
                <section class = "clr-input-wrapper">
                  <input
                    [formControl] = "port"
                    [placeholder] = "'порт'"
                    [type] = "'text'"
                    required
                    class = "clr-input"
                    id = "port"
                  >
                  <cds-icon class = "clr-validate-icon" shape = "exclamation-circle"></cds-icon>
                </section>
                @if (isControlHasErrors(port)) {
                  <span class = "clr-subtext">{{ getControlErrorMessage(port) }}</span>
                }
              </section>
            </section>
          }
          <section [ngClass] = "{'clr-error': isControlHasErrors(username) }" class = "clr-control-container">
            <section class = "clr-input-wrapper">
              <input
                [formControl] = "username"
                [placeholder] = "'Логин'"
                [type] = "'text'"
                required
                class = "clr-input"
              >
              <cds-icon class = "clr-validate-icon" shape = "exclamation-circle"></cds-icon>
            </section>
            @if (isControlHasErrors(username)) {
              <span class = "clr-subtext">{{ getControlErrorMessage(username) }}</span>
            }
          </section>
          <section [ngClass] = "{ 'clr-error': isControlHasErrors(password) }" class = "clr-control-container">
            <section class = "clr-input-wrapper">
              <input
                [type] = "state.isPasswordVisible ? 'text' : 'password'"
                [formControl] = "password"
                [placeholder] = "'Пароль'"
                required
                class = "clr-input"
              >
              <cds-icon
                (click) = "changePasswordVisibility()"
                [shape] = "state.isPasswordVisible ? 'eye-hide' : 'eye'"
                [size] = "'24'"
              ></cds-icon>
              <cds-icon class = "clr-validate-icon" shape = "exclamation-circle"></cds-icon>
            </section>
            @if (isControlHasErrors(password)) {
              <span class = "clr-subtext">{{ getControlErrorMessage(password) }}</span>
            }
          </section>
          @if (isCapsLockActive) {
            <section class = "alert alert-warning alert-sm">
              <section class = "alert-items">
                <section class = "alert-item">
                  <section class = "alert-icon-wrapper">
                    <cds-icon class = "alert-icon" [shape] = "'exclamation-triangle'"></cds-icon>
                  </section>
                  <span class = "alert-text">Внимание! Активен Caps Lock</span>
                </section>
              </section>
            </section>
          }
          @if (state.errorMessage) {
            <section class = "alert alert-danger">
              <section class = "alert-items">
                <section class = "alert-item">
                  <section class = "alert-icon-wrapper">
                    <cds-icon class = "alert-icon" [shape] = "'exclamation-circle'"></cds-icon>
                  </section>
                  <span class = "alert-text">{{ state.errorMessage }}</span>
                </section>
              </section>
            </section>
          }
          <button
            [disabled] = "isDisabled(state.isLoading)" class = "btn btn-primary" (click) = "login()"
          >Вход
          </button>
        </form>
      </section>
    </section>
  }
}
