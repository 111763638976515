import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from "@angular/common/http";
import { EventBrokerService } from "./event-broker.service";
import { Injectable } from "@angular/core";
import { ErrorSaverService } from "./eror-saver.service";
import { ErrorLogRecord } from "../models/error-log";
import { CurrentUserService } from "@app/shared/services/current-user.service";
import { firstValueFrom, Observable } from "rxjs";
import { ServerService } from "@app/services/server.service";
import { AuthService } from "@app/eager-modules/auth/services/auth.service";

@Injectable()
export class HttpService {
  withoutHandleError = false;

  constructor(
    private currentUserService: CurrentUserService,
    private errorSaver: ErrorSaverService,
    private authService: AuthService,
    private eventBroker: EventBrokerService,
    private httpClient: HttpClient,
    private serverService: ServerService
  ) {}

  private getHeaders(fresh: boolean = false, emitData?: boolean): Promise<HttpHeaders> {
    let headers = new HttpHeaders();
    if (emitData) {
      headers = headers.delete("Content-type");
    } else {
      headers = headers.append("Content-Type", "application/json");
    }

    return this.authService.getAccToken(fresh)
      .then((token) => {
        headers = headers.append("Authorization", token);
        return headers;
      })
      .catch((error) => Promise.reject(error));
  }

  commonRequest(urlEnd: string, type: "delete", body?: any): Promise<any> {
    if (!body) {
      body = {};
    }
    return this.every(
      (url: string, header: HttpHeaders) =>
        this.httpClient.request(type, url + urlEnd, {
          headers: header,
          responseType: "json",
          body
        }) as any
    );
  }

  delete(urlEnd: string): Promise<any> {
    return this.every(
      (url: string, header: HttpHeaders) =>
        this.httpClient.delete(url + urlEnd, {
          headers: header,
          responseType: "json"
        }) as any,
      null,
      urlEnd,
      "delete"
    );
  }

  post(
    urlEnd: string,
    body?: any,
    fileLoading?: boolean,
    formTransmit = false,
    type = "json"
  ): Promise<any> {
    if (!body) {
      body = {};
    }
    return this.every(
      (url: string, headers: HttpHeaders) => {
        let options = { headers, observe: "response" };
        options["responseType"] = fileLoading ? "arraybuffer" : type;
        return this.httpClient.post(url + urlEnd, body, <any>options) as any;
      },
      formTransmit,
      urlEnd,
      "post"
    );
  }

  put(urlEnd: string, body?: any, type: string = "json"): Promise<any> {
    if (!body) {
      body = {};
    }
    return this.every(
      (url: string, header: HttpHeaders) => {
        let options = {
          headers: header,
          observe: "response",
          responseType: type
        };
        return this.httpClient.put(url + urlEnd, body, <any>options) as any;
      },
      null,
      urlEnd,
      "put"
    );
  }

  get(urlEnd: string, fileLoading?: boolean, accept?: string, errorHandler?: Function): Promise<any> {
    return this.every(
      (url: string, headers: HttpHeaders) => {
        accept ? (headers = headers.append("Accept", accept)) : {};
        let options = { headers, observe: "response" };
        options["responseType"] = fileLoading ? "arraybuffer" : "json";

        return this.httpClient.get(url + urlEnd, <any>options) as any;
      },
      false,
      urlEnd,
      "get",
      errorHandler
    );
  }

  durationGet(urlEnd: string, fileLoading?: boolean, accept?: string): Promise<any> {
    return this.durationEvery(
      (url: string, headers: HttpHeaders) => {
        accept ? (headers = headers.append("Accept", accept)) : {};
        let options = { headers, observe: "response", reportProgress: true };
        options["responseType"] = fileLoading ? "arraybuffer" : "json";
        let req = new HttpRequest("GET", url + urlEnd, options);
        return this.httpClient.request(req) as any;
      },
      false
    );
  }

  getLargeFileData = async (fileId: number): Promise<any> =>
    this.getHeaders(false).then((headers: HttpHeaders): Observable<any> =>
      this.httpClient.get(
        this.authService.getUrl().concat(`files/download/${ fileId }`),
        { headers, responseType: "blob", observe: "events", reportProgress: true }
      )
    );

  durationEvery(
    httpFunc: (url: string, header: HttpHeaders) => Observable<any>,
    formTransmit?: boolean
  ): Promise<any> {
    return this.getHeaders(false, formTransmit).then((headers) =>
      httpFunc(this.authService.getUrl(), headers)
    );
  }

  fakeGet(url: string): Promise<any> {
    let options = { headers: null };
    return firstValueFrom(this.httpClient.get(url, options));
  }

  prevRequestUsername;

  every(
    httpFunc: (url: string, header: HttpHeaders) => Observable<any>,
    formTransmit?: boolean,
    url?: string,
    request?: "get" | "put" | "delete" | "post",
    errorHandler?: Function
  ): Promise<any> {
    return this.getHeaders(false, formTransmit)
      .then((headers) =>
        firstValueFrom(httpFunc(this.authService.getUrl(), headers))
      )
      .catch((error) => {
        let er = error as HttpErrorResponse;
        this.serverLogger(error);
        void this.handleError(error, request, url, errorHandler);
        if (er == null || er.status == 502 || er.status == 504) {
          this.eventBroker.emitGlobStatMessSent(
            "Произошла ошибка на сервере",
            "danger"
          );
        }
        if (
          er.status == 0 ||
          er.status == 403 ||
          (er.status == 401 &&
            this.prevRequestUsername == this.currentUserService.currentUser.username)
        ) {
          return this.getHeaders(true, false)
            .then((headers) =>
              firstValueFrom(httpFunc(this.authService.getUrl(), headers))
            )
            .catch((error) => {
              let er = error as HttpErrorResponse;
              void this.handleError(error, request, url, errorHandler);
              if (
                er == null ||
                er.status == 0 ||
                er.status == 502 ||
                er.status == 504
              ) {
                this.eventBroker.emitGlobStatMessSent(
                  "Произошла ошибка на сервере",
                  "danger"
                );
              }
              if (er.status == 403 || er.status == 401) {
                this.handleErrorWithDescription(
                  url,
                  request,
                  "Доступ запрещён"
                );
              }
              return error ? Promise.reject(error) : Promise.reject(null);
            });
        }
        if (er.status !== 403) {
          return Promise.reject(error);
        }
      })
      .finally(() => {
        this.prevRequestUsername =
          this.currentUserService && this.currentUserService.currentUser
            ? this.currentUserService.currentUser.username
            : "";
      });
  }

  private serverLogger(error: HttpErrorResponse) {
    if (window.location.hostname !== "localhost") {
      let report = new ErrorLogRecord(
        JSON.stringify(error.error),
        window.location.host + location.pathname,
        "",
        this.currentUserService.currentUser
          ? this.currentUserService.currentUser.username
          : "",
        this.currentUserService.currentUser.id,
        this.serverService.getBackendServerUrlWithPort(),
        null,
        error.status + "",
        error.url
      );
      void this.errorSaver.transmitError(report);
    }
  }

  private handleError(error: any, request, url, errorHandler?: Function) {
    if (this.withoutHandleError) {
      return;
    }

    if (
      request == "get" &&
      error.status !== 0 &&
      error.status !== 403 &&
      error.status !== 401 &&
      url !== "jobs"
    ) {
      if (!errorHandler) {
        switch (true) {
          case error.status === 404: {
            typeof error.error === "string"
              ? this.eventBroker.emitGlobStatMessSent(
                "Запрашиваемый ресурс не найден: " + error.error,
                "danger"
              )
              : this.handleErrorWithDescription(
                url,
                request,
                "Запрашиваемый ресурс не найден"
              );
            break;
          }
          case error.status > 499 && error.status < 600: {
            this.handleErrorWithDescription(
              url,
              request,
              "При обработке запроса произошла ошибка на сервере"
            );
            break;
          }
          default: {
            this.handleErrorWithDescription(
              url,
              request,
              "Запрос не выполнен, произошла ошибка"
            );
            break;
          }
        }
      } else {
        errorHandler(error);
      }
      return error ? Promise.reject(error) : Promise.reject(null);
    }
  }

  swaggerData: any;

  getSwaggerData(): Promise<boolean> {
    if (!this.swaggerData) {
      return firstValueFrom(this.httpClient
        .get(this.authService.getUrl() + "v2/api-docs"))
        .then((data) => {
          this.swaggerData = data;
          return Promise.resolve(true);
        });
    } else {
      return Promise.resolve(true);
    }
  }

  private handleErrorWithDescription(
    url: string,
    request: "get" | "put" | "delete" | "post",
    message: string
  ) {
    this.getSwaggerData()
      .then(() => {
        let segments = url.split("/");
        let needSegments = [];
        let segmentsForCompare = [];
        segments.forEach((s) => {
          s ? segmentsForCompare.push(s) : {};
          isNaN(+s) && s
            ? needSegments.push(
              s.indexOf("?") >= 0 ? s.slice(0, s.indexOf("?")) : s
            )
            : {};
        });
        let keys = Object.keys(this.swaggerData.paths);
        let needKey;
        keys.forEach((key) => {
          let flag = true;
          let keySegments = [];
          key.split("/").forEach((k) => {
            !k.includes("{") && k ? keySegments.push(k) : {};
          });
          keySegments.forEach((k, index) => {
            k != needSegments[index] ? (flag = false) : {};
          });
          needSegments.forEach((s, index) => {
            s != keySegments[index] ? (flag = false) : {};
          });
          flag && segmentsForCompare.length === key.split("/").length - 1
            ? (needKey = key)
            : {};
        });
        this.eventBroker.emitGlobStatMessSent(
          needKey && this.swaggerData.paths[needKey][request]
            ? message +
            ": " +
            this.swaggerData.paths[needKey][request]["summary"]
            : message,
          "danger"
        );
      })
      .catch(() => {
        this.eventBroker.emitGlobStatMessSent(message, "danger");
      });
  }
}
